@import '../../../../styles/colors.scss';

.details {
  &__details-checkbox {
    display: flex;
    align-items: center;
  }

  &__text {
    color: $defaultColor;
    margin: 0px 15px 0px 0px;
  }

  &__fio {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-top: 18px;
  }

  &__field {
    width: 30%;
    height: 38px;
    border: 1px solid $checkboxBorder;
    border-radius: 5px;
    background-color: $checkboxBackground;
    color: $checkboxBorder;
    padding: 7px 5px;
    text-align: center;
    margin: 0px;
  }
}
