@import '../../../styles/colors.scss';

.whiteButton {
  background-color: white;
  color: $defaultColor;
}

.hasBorder {
  border: 1px solid;
  border-radius: 5px;
}

