@import 'styles/colors.scss';

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/open_sans.ttf');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/segoe_ui.ttf');
}

@font-face {
  font-family: 'Mplus 1p';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Mplus1p-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  src: url('assets/fonts/OpenSans-Bold.ttf');
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Open Sans', sans-serif;
  outline: none !important;
}

#root {
  position: relative;
}
