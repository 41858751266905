@import '../../../../../styles/colors.scss';

.user-card-contr-agents {
  margin-top: 3px;
  
  &__first-row {
    display: flex;
    background: $adminGrey;
    padding: 4px 0px 7px 0px;
  }

  .custom-table-row__item {
    background: $whiteColor !important;
  }

  .custom-table-row__headers {

    * {
      background: $tableHeaderColor !important;
    }

  }

  &__table {
    max-height: 170px;
    overflow: auto;
  }

  &__control-btn {
    cursor: pointer;
    width: 100%;
    padding: 0;
  }

}
