@import 'src/styles/colors.scss';

.tab-select {
  &__wrapper {
    display: flex;
    justify-content: left;
    margin: 0px 0px 75px -35px;

    &_is-month {
      margin-bottom: 45px;
    }
  }

  &__barrier-percent {
    font-size: 18px !important;
  }

  &__selected-period {
    font-size: 12px;
    font-weight: 400;
    margin-left: 30%;
    color: $activeTabOpacity;
  }

  &__period {
    font-size: 12px;
    margin-left: 30%;
    color: $defaultColor;
  }

  &__tab-selected {
    background-color: $defaultColor;
    color: $whiteColor;
    width: 120%;
    border-radius: 5px;
    padding-bottom: 5px;
  }

  &__selected-border {
    border-bottom: 0.5px solid $whiteColor;
    width: 90%;
    margin: auto;
  }

  &__border {
    border-bottom: 0.5px dashed $lightBlockBorder;
    width: 90%;
    margin: auto;
  }

  &__barrier {
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
    color: $defaultColor;
  }

  &__barrier-selected {
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
    color: $whiteColor;
  }

  &__border-past {
    border-bottom: 0.5px dashed $defaultColor;
  }

  &__item {
    padding: 0 20px;
    background: unset;
    width: max-content;
    color: $defaultColor;
    font-size: 20px;
    cursor: pointer;
    border: none;
    transition: 0.3s ease-in-out all;
    margin: 0 15px;
    height: 50px;
    position: relative;

    &:after {
      content: attr(data-mark);
      position: absolute;
      font-size: 12px;
      bottom: -30px;
      left: 50%;
      top: 100%;
      transform: translateX(-20%);
    }

    &-selected {
      font-size: 26px;
      font-weight: 700;
      background: transparent;
      position: relative;

      &_is-month {
        padding: 0;
        margin: 0 10px;
        top: 1px;
        font-size: 16px;
      }
    }

    &-disabled {
      pointer-events: none;
      background: transparent;
      border: none;
      color: $lightBlockBorder;
    }

    &_is-month {
      margin: 0 10px;
      background-color: unset;
      font-size: 16px;
      padding: 0;
      height: unset;

      &:after {
        content: attr(data-mark);
        position: absolute;
        font-size: 13px;
        font-weight: normal;
        bottom: -20px;
      }
    }
  }
}

@media (max-width: 990px) {
  .tab-select {
    &__wrapper {
      flex-wrap: wrap;
    }

    &__item {
      font-size: 14px;
      margin-bottom: 30px;

      &:after {
        font-size: 12px;
      }
    }
  }
}
