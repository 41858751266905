@import '../../../../styles/colors.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-wrapper {
  position: relative;
  text-align: left;
  margin-top: 8px;
  width: 45%;

  &__label {
    position: absolute;
    top: 0.2ex;
    z-index: 1;
    left: 2em;
    background-color: $whiteColor;
    padding: 0 5px;
    color: $defaultColor;
    font-size: 12px;
  }

  &__input {
    border: 1px solid $inputDashed;
    border-radius: 5px;
    position: relative;
    width: 100%;
    margin-top: 10px;
    line-height: 6ex;
    padding-left: 35px;
    font-size: 12px;
    color: $defaultColor;
  }

  .ui.input>input {
    border: 1px solid $inputDashed;
    border-radius: 5px;
    position: relative;
    width: 100%;
    margin-top: 10px;
    height: 42.53px;
    padding-left: 35px;
    font-size: 12px;
    color: $defaultColor;
  }

  .ui.input {
    width: 100%;
  }
}
