@import '../../../../styles/colors.scss';

.info-block-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $greyText;
  width: 100%;
  max-width: 469px;

  &_errorMessage {
    color: $errorColor;
  }

}
