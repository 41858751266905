@import '../../styles/colors.scss';

.admin-form-interface {
  padding: 15px;
  background-image: url('../../img/main_logo_big.jpg');
  background-size: cover;
  min-height: calc(100vh - 100px);
  
  &__content {
    max-width: 1440px;
    border: 2px solid $adminBorderColor;
    background-color: $adminFormColor;
    width: 100%;

    &_max {
      max-width: unset;
    }
  }
}
