@import '../../../../styles/colors.scss';

.users-form {
  height: 637px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    display: flex;
  }

  &__search {
    display: flex;
  }

  &__filter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15.36px;
    line-height: 18px;
    width: 100%;
    max-width: 101px;
    height: 42px;
    background: $adminGrey;
  }
  
  &__search-input {
    width: 100%;
    border: 1px solid $adminGrey;
    padding: 9px 14px;
  }
}
