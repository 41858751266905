.flex-table-contacts {
  
  &__control-btns {
    display: flex;
  }

  &__control-btn {
    height: 28px;
    width: 100%;
    max-width: 25px;
  }

  .ui.selection.dropdown {
    min-width: unset !important;
    width: 100%;
  }

  &__contact-input {
    width: 100%;
  }
}
