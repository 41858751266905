@import '../../../../styles/colors.scss';

.contract-status {
  display: flex;

  &__status {
    color: $whiteColor;
    font-size: 16px;
  }

  &__img {
    margin-right: 15px;
    width: 19px;
    height: 20px;
  }

}
