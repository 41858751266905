@import '../../../../styles/colors.scss';

.bank-requisites {
  text-align: left;
  margin: 30px 0px 30px 0px;

  &__text {
    margin-bottom: 10px;
    color: #162E5E;
  }

  &__block {
    display: flex;
    width: 60%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__drop-down {
    width: 100%;
    height: 30px;
  }

  .ui.input>input {
    border: 1px solid $inputDashed;
    border-radius: 5px;
    position: relative;
    width: 100%;
    margin-top: 10px;
    height: 42.53px;
    padding-left: 35px;
    font-size: 12px;
    color: $defaultColor;
  }

  &__input-wrapper {
    position: relative;
    text-align: left;
    margin-top: 8px;
    width: 45%;
  }

  .ui.input {
    width: 100%;
  }
  }
