@import 'src/styles/colors.scss';

.attention-popup {

  &__popup {
      display: flex;
      width: 450px;
    }

  &__popup-border {
    border-left: 1px solid $defaultColor;
    margin-right: 23px;
  }

  &__popup-trigger {
    display: flex;
    color: $defaultColor;
  }

  &__popup-attention-icon {
    display: flex;
    align-items: center;
    font-size: 60px;
    color: $defaultColor;
    margin-right: 12px;
  }

  &__popup-text {
    color: $grayTextColor;
  }
  
  &__popup-trigger {
    cursor: pointer;
  }

  &__popup-trigger-icon {
    width: 25px;
    height: 25px;
    margin-right: 3px;
    border: 1px solid $defaultColor;
    border-radius: 5px;
  }

  &__popup-trigger-text {
    font-size: 22px;
    margin-top: -5px !important;
  }
}
