@import '../../../../styles/colors.scss';

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.personal-info-popup {
  margin: 50px 70px 50px 75px;
  width: 677px;

  &__passport-data {
    width: 100%;
    max-width: 469px;
  }

  &__first-row {
    display: flex;
  }

  &__first-passport-block,
  &__title,
  &__passport-value {
    width: 50%;
  }

  &__first-passport-block, &__passport-block {
    display: flex;
    height: 50px;
  }

  &__title {
    background: $tablePassportBack;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: $buttonBorder;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $whiteColor;
  }

  &__passport-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    border-bottom: 1px solid $tablePassportBack;
  }

  &__personal-info-row, &__contacts-row {
    min-height: 50px;
    display: flex;
  }

  &__contacts-row:first-child {

    .personal-info-popup__contacts-title{
      border-top-left-radius: 5px;
    }

  }

  &__contacts-row:last-child {

    .personal-info-popup__contacts-title {
      border-bottom-left-radius: 5px;
    }

  }

  &__personal-info-row:first-child {

    .personal-info-popup__personal-title {
      border-top-left-radius: 5px;
    }

  }

  &__personal-info-row:last-child {

    .personal-info-popup__personal-title {
      border-bottom-left-radius: 5px;
    }

  }

  &__contacts-row {
    height: 50px;
  }

  &__personal-title {
    min-height: 50px;
  }

  &__personal-title, &__contacts-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $whiteColor;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    background: $defaultColor;
    width: 100%;
    max-width: 215px;
    border-top: 1px solid $popUpBorder;

    @include only-ie-11 {
      max-width: 290px;
    }
  }

  &__contacts-title {
    width: 100%;
    max-width: 312px;
  }

  &__personal-info-data, &__contacts-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    width: 100%;
    border-top: 1px solid $popUpBorder;
    color: $buttonBorder;
    text-align: center;
  }

  &__contact {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    width: 70%;
    border: none;
    text-align: center;
    background: $whiteColor;
  }

  &__contact:disabled,
  &__contact[disabled] {
    color: $buttonBorder !important;    
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: $buttonBorder;
    margin-bottom: 20px;
    align-items: center;
  }

  &__personal-data, &__contacts-data {
    border-radius: 5px;
    border: 1px solid $popUpBorder;
  }

  &__contacts-data {
    height: 100%;
    max-height: 300px;
    overflow: auto;
  }

  &__personal-data {
    margin-bottom: 20px;
  }

  &__delete-contact {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $buttonBorder;
    height: 100%;
    margin-left: 10px;
  }

  &__contact-control {
    display: flex;
    align-items: center;
    width: 30%;
    height: 100%;
  }

}
