@import '../../../../styles/colors.scss';

.success-import-popup {
  padding: 15px 0;
  border-radius: 0;
  max-width: 670px;
  height: 115px;
  width: 100%;

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  &__text {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    text-align: center;
    font-size: 15px;
    margin-right: 3px;
    padding: 0 15px;
  }

  &__text-wrapper {
    display: flex;
    justify-content: center;
  }

  .popup-container {
    &__close-btn {
      margin: 5px 10px;

      img {
        width: 18px;
      }
    }
  }
}
