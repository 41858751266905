.user-card-form {

  &__content {
    display: flex;
    justify-content: space-between;
    height: 577px;
  }

  &__left-side, &__right-side {
    width: 50%;
  };

  &__right-side {
    margin-left: 4px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }

  &__footer-btn {
    width: 165px;
    max-width: 100%;
    height: 35px;
    border-radius: 4px;

    &_margin {
      margin-right: 10px;
    }
  }

  &__operation-btn {
    display: flex;
  }
}
