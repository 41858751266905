@import '../../../../styles/colors.scss';

.white-table {
  border-spacing: 0px;
  border-collapse: unset;
  width: 100%;

  tbody {
    display: block;
    max-height: 630px;
    overflow: auto;
    width: 100%;
  }
  tr {
    display: flex;
    align-items: center;
  }
  th {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  td {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__header {
    background-color: $defaultColor;
    border: 1px solid $whiteColor;
    border-left: 0;
    padding: 10px;
    color: $whiteColor;
    height: 70px;
    font-size: 12px;

    &:first-child {
      border-left: 1px solid $whiteColor;
      border-radius: 5px 0 0;
    }

    &:last-child {
      border-radius: 0 5px 0 0;
    }
  }

  &__value {
    background-color: $whiteColor;
    height: 70px;
    border: 1px solid $whiteTableBorder;
    border-top: 0;
    border-left: 0;
    font-size: 13px;

    &:first-child {
      border-left: 1px solid $whiteTableBorder;
    }
  }

  &__row {
    &:last-child {
      .white-table {
        &__value {
          &:first-child {
            border-radius: 0 0 0 5px;
          }

          &:last-child {
            border-radius: 0 0 5px 0;
          }
        }
      }
    }
  }
}
