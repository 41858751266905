@import '../../../../styles/colors.scss';

.admin-preview-input {
  width: 470px;
  height: 70px;

  &__input {
    background-color: $inputBackground;
    border: none;
    width: 100%;
    height: 100%;
    padding: 14px 16px;
    border-radius: 5px;
    height: 50px;

    &::placeholder {
      font-size: 14px;
    }

    &_error {
      background-color: $adminInputErrorBackground;
    }

    &:focus {
      outline: none;
    }
  }

  &__error {
    margin: 0;
    padding: 0;
    padding-left: 5px;
    color: $redColor;
    text-align: left;
  }

}

