@import '../../../../styles/colors.scss';

.admin-popup {
  background-color: $adminFormColor;
  padding: 15px 0;
  border-radius: 0;

  .popup-container {
    &__close-btn {
      margin: 5px 10px;

      img {
        width: 18px;
      }
    }
  }

  &__veil {
    background-color: $adminPopupTransparent;
  }
}
