@import '../../../styles/colors.scss';

.input-button {
    max-width: 120px;
    width: 100%;
    height: 28px;
    background-color: $buttonGreen;
    border-radius: 1px;
    color: $whiteColor;
    font-weight: bold;
    line-height: 19px;
    border: none;
}

.input-button:disabled,
.input-button[disabled] {
    cursor: default;
    opacity: .45!important;
    background-image: none!important;
    box-shadow: none!important;
    pointer-events: none!important;
    border: none;
}
