@import '../../../../styles/colors.scss';

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@font-face {
  font-family: 'Mplus 1p';
  font-style: normal;
  font-weight: normal;
  src: url('../../../../assets/fonts/Mplus1p-Regular.ttf');
}

.footer {
  width: 100%;
  background: $defaultColor;

  &__wrapper {
    margin: 0 auto;
    padding: 42px 0px 40px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1240px;
  }

  @media (max-width: 1240px) {
    &__wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  &__left-side {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $footerColorWhite;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__right-side {
    font-family: 'Mplus 1p';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    color: $footerColorWhite;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__license-row {
    font-family: 'Mplus 1p', sans-serif;
  }
  
  &__contact-info {
    margin-bottom: 27.5px;
    margin-top: 10px;
    color: $whiteColor !important;
    display: flex;
  }

  &__social-network {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 11.7px;
    line-height: 19px;
  }

  &__phone-call {
    color: $whiteColor !important;
    font-style: normal;
    font-family: 'Open Sans Bold';
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    z-index: 1000;

    @include only-ie-11 {
      font-family: 'Open Sans', sans-serif;
    }
  }

  &__social-text {
    font-family: 'Mplus 1p', sans-serif;
  };
  
  &__logo {
    cursor: pointer;
    margin-bottom: 28px;
    z-index: 1000;
  }

}
