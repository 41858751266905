@import '../../../../../styles/colors.scss';

.isz-redemption-sums-data {
  background-image: url('./../../../../../assets/images/isz-redemption-sums.png');

  .white-table {
    border-spacing: revert;
    tbody {
      max-height: 555px;
      overflow: unset;
    }
    &__header {
      font-size: 13px;
    }
    &__header:last-child {
      width: 58%;
    }
    &__value {
      height: 37px !important;
    }
    &__value:last-child {
      width: 60%;
    }
  }

  &__header-form-text {
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: $whiteColor;
    text-align: left;
    width: 100%;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }

  &__content {
    max-width: 881px;
    width: 100%;
    padding: 4.219% 3.698%;
  }

  &__wrapper {
    width: 100%;
    max-width: 1240px;
    display: flex;
    margin: 0 auto;
    flex-direction: row-reverse;
    height: 100%;
  }

  .info-block-mini {
    font-size: 13px;
    max-width: 100%;
    text-align: left;
    margin-top: 20px;
  }

  &__tables {
    display: flex;
  }

  &__left-table {
    overflow: auto !important;
    margin-right: 30px;
    width: 100%;
  }

  &__right-table {
    overflow: auto !important;
    display: flex;
    width: 100%;
  }

  &__selected-row {
    background-color: #d0d5df;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      line-height: 15px;
    }
  }
}
