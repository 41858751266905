@import '../../../../styles/colors.scss';

.reset-password-form {
  background: url('../../../../assets/images/blurBack.png') no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 80px);
  width: 100%;

  &__form {
    padding: 50px 84px;
    width: 100%;
    max-width: 637px;
  }

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 30px;
    color: $buttonBorder;
  }

  &__title {
    color: $defaultColor;
    margin-bottom: 10px;
    text-align: left;
    font-size: 20px;
  }

  &__reset-button {
    margin-top: 20px;
  }

  &__change-password-wrapper {
    .change-password-form__header {
      display: none;
    }
  }
}
