@import '../../../../styles/colors.scss';

.row-product {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $documentFormColor;
  height: 100%;
  max-height: 60px;

  padding-left: 10px;
  border: 1px solid $borderColor;

  &_big {
    height: 156px;
  }


  &__text-wrapper {
    margin: 0;
    padding: 0;
    width: 70%;
    text-align: left;
    word-break: break-word;

    &:first-child {
      color: $borderColor;
      width: 30%;

      @media only screen and (max-width: 1000px) {
        width: 45%;
      }
    }
  }

  &__description {
    overflow: auto;
    border: 1px solid $borderColor;
    background-color: $documentFormColor;
    padding: 5px;

    p {
      padding: 0;
      margin: 0;
    }

    .fund {

      &__name {
        font-size: 1.2rem;
        display: block;
      }

      &__contentWrap {
        padding: 0;
        border: none;
      }

      &__dataWrap__value {
        font-size: 1rem
      }

      &__dataWrap {
        padding: 0;
        padding-bottom: 3px;
      }
    }
  }
}
