@import './../../../../styles/colors.scss';

.admin-head-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  width: 100%;
  border-bottom: 1px solid $adminTransparentBorder;

  &__logo {
    width: 165px;
    height: 60px;
  }

  &__right-panel-wrapper {
    display: flex;
    align-items: center;
  }

  &__info {
    margin: 0;
    padding: 0;
    margin-right: 20px;
  }

  &__burger-button {
    width: 48px;
    height: 48px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

