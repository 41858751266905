@import './../../../../../styles/colors.scss';

.isz-payments-data {
  background-image: url('../../../../../assets/images/isz-data-payments.png');

  ::-webkit-scrollbar {
    border-radius: 5px;
    background-color: $scroll;
  }

  &__header-form-text {
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: $whiteColor;
    text-align: left;
    width: 100%;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }

  &__header {
    display: flex;
    font-size: 14px;
    color: $whiteColor;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
  }

  &__header-item {
    width: 120px;
  }

  &__payment-status-current-paid {
    color: $whiteColor;
    width: 80%;
    height: 30px;
    background-color: $buttonGreen;
    border-radius: 5px;
  }

  &__payment-status-current-future {
    color: $whiteColor;
    width: 80%;
    height: 30px;
    background-color: $notActiveTab;
    border-radius: 5px;
  }

  &__payment-status-current-not-paid {
    color: $whiteColor;
    width: 80%;
    height: 30px;
    background-color: $loss;
    border-radius: 5px;
  }

  &__payment-status {
    padding-top: 5px;
  }

  &__content {
    max-width: 1240px;
    width: 100%;
    padding: 4.219% 3.698%;
    margin: 0 auto;
    height: 100%;
    background-color: $defaultColor;
  }

  &__paided-block {
    display: flex;
    height: 47px ;
    margin-top: 1%;
    width: 100%;
  }

  &__wrapper {
    width: 100%;
  }

  &__good, &__sum, &__bad {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 129px;
    border-radius: 5px;
    border: 1px solid $popUpBorder;
    background-color: $whiteColor;
    color: $blackColor;
    font-size: 13px;
    line-height: 18px;
  }

  &__sum {
    border-radius: 0px 5px 5px 0px;
  }

  &__good, &__bad {
    background: $buttonGreen;
    color: $whiteColor;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    border-radius: 5px 0px 0px 5px;
  }

  &__bad {
    background: $errorColor;
  }

  &__simple {
    overflow: auto;
    overflow-x: hidden;

    .white-table {
      tbody {
        max-height: 490px;
        width: 100%;
        overflow: unset;
      }
      tr:first-child {
        td:first-child {
          border-radius: 5px 0px 0px 0px;
        }
        td:last-child {
          border-radius: 0px 5px 0px 0px;
        }
      }
    }
  }

  &__simple-dimple {
    overflow: auto;
    overflow-x: hidden;

    .white-table {
      tbody {
        max-height: 490px;
        width: 100%;
        overflow: unset;
      }
      tr:first-child {
        td:first-child {
          border-radius: 5px 0px 0px 5px;
        }
        td:last-child {
          border-radius: 0px 5px 5px 0px;
        }
      }
    }
  }

  .info-block-mini {
    max-width: 100%;
    text-align: left;
    color: $defaultColor;
    margin-top: 20px;
  }
}

