@import "src/styles/colors";

.paymentUploads {
  display: flex;
  margin-left: 20%;

  &__back-button {
    border: none;
    background-color: $whiteColor;
    padding: 0px;
    position: absolute;
    top: 36px;
    right: 30px;
  }

  &__container {
    position: relative;
  }

  &__inner-container {
    padding: 30px;
  }

  &__arrow-back {
    border: none;
    background-color: $whiteColor;
    padding: 0px;
    position: absolute;
    left: 30px;
    top: 36px;
  }

  &__arrow {
    width: 32px;
    height: 32px;
  }

  &__header {
    font-size: 27px;
    color: $defaultColor;
    text-align: left;
    margin-top: 64px !important;
  }

  &__upload-txt {
    font-size: 17px;
    color: $defaultColor;
    margin-top: 35px;
    text-align: left;
  }

  &__avatar-upload {
    width: 70%;
    display: flex;
    align-items: center;
    padding: 14px 36px;
    border: 1px dashed $defaultColor;
    border-radius: 5px;
    background-color: $checkboxBackground;
    cursor: pointer;
  }

  &__input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__upload-text {
    margin-left: 18px;
    color: $inputDashed;
  }

  &__checkbox-container {
    margin: 18px 0px 35px 0px;
  }

  &__agreement {
    color: $defaultColor;
    text-align: left;
  }

  &__sms-block {
    width: 80%;
    display: flex;
    align-items: center;
  }

  &__sms-button-send {
    border: 1px solid $buttonGreen;
    border-radius: 5px;
    color: $buttonGreen;
    font-size: 17px;
    padding: 10px 25px;
    background-color: $whiteColor;
    white-space: nowrap;
  }

  &__sms-text-send {
    text-align: left;
    margin-left: 35px;
    color: $defaultColor;
    font-size: 17px;
  }

  &__sms-code {
    display: flex;
    text-align: center;
    border: 1px solid $cardBorderColor;
    border-radius: 5px;
    padding: 7px 35px;
    color: $defaultColor;
    font-size: 17px;
  }

  &__sms-code-block {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }

  &__sms-code-text {
    margin-left: 18px;
    font-size: 17px;
    color: rgba(22, 46, 94, 0.5);
    border-bottom: 1px solid rgba(22, 46, 94, 0.5);;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $popUpBorder;
    border-radius: 5px;
    background-color: $buttonGreen;
    width: 206px;
    height: 38px;
    color: $whiteColor;
    font-size: 17px;
  }

  &__arrow-icon {
    margin-bottom: 18px;
  }

  &__image {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 18px;
    color: $defaultColor;
  }

  &__del-icon {
    width: 14px;
    height: 14px;
    margin-left: 10px;
  }
}
