@import '../../../../styles/colors.scss';

.managment-admin-form {
  &__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 10px;
    background-color: $adminFormColor;
  }

  &__section-title {
    margin: 0;
    padding: 0;
  }

  &__button {
    width: 165px;
    height: 35px;
  }

  &__input-file {
    &:focus {
      outline: none;
    }
  }

  &__error {
    text-align: right;
    margin: 0px 10px 0px 0px;
    color: $redColor;
  }

  .ui.dropdown {
    padding-right: 7px;
  }

  &__select {
    min-width: 300px;
    min-height: 25px;
    background-color: $whiteColor;
  }

  .ui.dropdown>.text {
    margin-left: 10px;
  }

  &__dropdown {
    background-color: $whiteColor;
  }
}

.admin-global-settings {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $adminFormColor;

  &__content {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 7px 0;
  }
}

.section-admin-managment-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  background-color: $adminFormColor;

  &__title {
    margin: 0;
    padding: 0;
  }
}
