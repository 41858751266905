@import '../../../../styles/colors.scss';

.checkbox {
  display: none;
  margin-left: -12px;

  + .checkbox_custom {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    cursor: pointer;

    &:before {
      content: '';
      padding: 2px;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
      background: $checkboxBackground;
      border-radius: 5px;
      border: 1px solid $checkboxBorder;
    }
  }

  + .checkbox_cursor {
    cursor: unset;
  }

  &:checked + .checkbox_custom {
    &:after {
      content: "";
      padding: 2px;
      width: 6px;
      height: 14px;
      position: absolute;
      top: calc(50% - 2px);
      left: 20px;
      transform: translateY(-50%) rotate(45deg);
      border: solid $checkboxBorder;
      border-width: 0 2.5px 2.5px 0;
    }
    &:before {
      background: $checkboxBackground;
      border-color: $checkboxBorder;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin: 0px;
    font-size: 17px;
    color: $defaultColor;
  }

  &__text {
    text-align: left;
  }
}

.adminCheckbox {
  display: none;

  + .checkbox_custom {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    cursor: pointer;

    &:before {
      content: '';
      padding: 2px;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
      background: $whiteColor;
      border-radius: 3px;
      border: 2px solid $adminBorderColor;
    }
  }

  &:checked + .checkbox_custom {
    &:after {
      content: "";
      padding: 2px;
      width: 10px;
      height: 16px;
      position: absolute;
      top: calc(50% - 2px);
      left: 20px;
      transform: translateY(-50%) rotate(45deg);
      border: solid $whiteColor;
      border-width: 0 2.5px 2.5px 0;
    }
    &:before {
      background: $adminCheckboxBackground;
      border-color: $adminCheckboxBackground;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin: 0px;
    font-size: 14px;
  }

  &__text {
    margin-right: 5px;
  }
}

