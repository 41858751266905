@import '../../../../styles/colors.scss';

.slider-control {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $whiteColor;
  height: 79px;
  flex-direction: column;

  &__panel {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    button {
      background: $whiteColor;
    }
  }

  &__arrow {
    width: 23px;
    height: 10px;

    &_left {
      margin-right: 13px;
    }

    &_right {
      transform: rotate(180deg);
      margin-left: 13px;
    }
  }

  &__button {
    border: 0;
    border-radius: 0;
    font-size: 13px;
    font-weight: bold;
    width: 30%;
    display: flex;

    &_left {
      justify-content: flex-start;
    }

    &_right {
      justify-content: flex-end;
    }

    &:focus {
      outline: none;
    }
  }

  &__dots {
    height: 21px;

    .carousel {
      &__dot {
        border: 4px solid $borderScrollControl;
        height: 25px;
        width: 25px;
        transform: scale(0.5);
        border-radius: 20px;
        margin: 0 24px
      }

      &__dot--selected {
        background-color: $backgroundScrollControl;
      }
    }
  }
}
