@import '../../../../styles/colors.scss';

.info-table {

  &__custom-row {
    padding: 2px !important;
  }

  &__header-block {
    background-color: $adminSideMenuItemColor;
    height: 25px;
    border: 1px solid $borderColor;
    margin: 2px;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    padding-left: 10px;

    &:last-child {
      margin-bottom: 2px;
    }
  }
}

.row { 
  margin: 0px;
}
