@import '../../../../styles/colors.scss';

.admin-section-title {
  display: flex;
  align-items: center;
  padding: 0 14px;
  margin: 1px 0;
  height: 30px;
  background-color: $adminGreyFormColor;
  text-align: left;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  justify-content: space-between;

  &__control-btn {
    height: 28px;
    width: 25px;
  }

  &__text-wrapper {
    margin: 0px;
  }
}
