@import '../../../../styles/colors.scss';

.contracts-tabs {
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 40px;
  width: 100%;
  max-width: 1240px;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__tab {
    width: 100%;
    max-width: 386px;
    background: $notActiveTab;
    position: relative;
    border: 0px solid red;
    border-radius: 5px;

    &_active {
      background: $defaultColor;
    }

  }

  &__content {
    display: flex;
    align-items: center;
    margin: 12px 42px;
  }

  &__text {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: $whiteColor;
    text-align: left;
    margin-left: 44px;
  }

  &__choosed {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 22px 22px;
    border-color: transparent transparent $buttonGreen transparent;
    bottom: 0;
    right: 0;
  }

}
