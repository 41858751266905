@import '../../../../styles/colors.scss';

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.change-contact-form {
  margin: 50px 41px 30px 34px;

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 41px;
    color: $buttonBorder;
    text-align: center;
    max-width: 560px;
    width: 100%;
    font-family: 'Open Sans Bold';

    @include only-ie-11 {
      font-family: 'Open Sans', sans-serif;
    }
  }

  &__input-field, &__description {
    margin-top: 11px;
    display: flex;
    justify-content: center;
  }

  &__description {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__control-btn {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }

  &__error-input {
    border: 1px solid $errorColor !important;
  }

}
