@import '../../../../styles/colors.scss';

.admin-main-button {
  display: flex;
  align-items: center;

  &__button {
    border: none;
    background-color: $adminButtonColor;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;

    &:disabled {
      opacity: 0.3;
      color: $blackColor;
      cursor: unset;
    }

    &:focus {
      outline: none;
    }
  }
}
