@import '../../../../styles/colors.scss';

.transparent-table {
  color: $whiteColor;
  border-spacing: 0px;
  border-collapse: collapse;
  font-size: 17px;
  position: relative;

  tbody {
    display: block;
    overflow: auto;
    width: 100%;
  }

  &__header {
    position: static;
    font-weight: bold;
    text-align: left;
    padding: 0 20px;
  }

  &__value {
    padding: 0 20px;
    text-align: left;

    &:last-child {
      border-radius: 0 5px 5px 0;
      text-align: right;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }
  }

  &__desc {
    margin: 20px 0px 0px 20px;
    text-align: left;
    font-size: 12px;
  }

  &__row {
    height: 48px;

    &:nth-child(2n) {
      background-color: $backgroundSecondRowTransparentTable;
    }

    &_first {
      &:nth-child(2n+1) {
        background-color: $backgroundSecondRowTransparentTable;
      }

      &:nth-child(2n) {
        background-color: unset;
      }
    }
  }
}
