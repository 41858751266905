@import './../../../../styles/colors.scss';

.admin-footer-panel {
  height: 20px;
  width: 100%;
  border-top: 1px solid $adminTransparentBorder;
  display: flex;
  align-items: center;
  padding: 0 7px;

  &__ip {
    margin: 0;
    padding: 0;
    margin-right: 15px;
  }

}