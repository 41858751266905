@import '../../../../../styles/colors.scss';

.admin-personal-card {
  font-size: 16px;
  line-height: 19px;
  width: 40%;

  &__header-block-title,
  &__text-wrapper,
  &__date-password,
  &__roles-title,
  &__role-title {
    text-align: left;
    margin: 0;
  }

  .admin-main-button__button  {
    width: 100%;
    max-width: 155px;
    height: 43px;
    border-radius: 4px;
  }

  &__label-text {
    width: 25%;
    font-weight: bold;
    text-align: left;
    padding-left: 3px;
    margin: 0;
  }

  &__prop-text {
    word-wrap: break-word;
    width: 75%;
    text-align: left;
    margin: 0;
  }

  &__prop-content {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $adminGrey;
    margin-bottom: 1px;
    min-height: 40px;
  }

  &__password-input {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $adminGrey;
    padding: 1px 8px 4px 3px;
  }

  &__header-block {
    font-weight: bold;
    display: flex;
  }

  &__header-block-title {
    margin-right: 5px !important;
  }

  &__content {
    margin-top: 1px;
  }

  &__password-row {
    margin-right: 10px;
    width: 75%;
  }

  &__password-area {
    width: 100%;
    height: 100%;
    min-height: 150px;
    border: none;
    resize: none;
  }

  &__password-wrapper {
    width: 100%;
    max-width: 527px;
    min-height: 55px;
    display: flex;
  }

  &__control-password {
    background-color: $whiteColor;
    cursor: pointer;
  }

  &__control-btn {
    width: 36px;
    height: 29px;
  }

  &__date-password {
    width: 100%;
    max-width: 68px;
    word-break: break-word;
  }

  &__roles-row {
    display: flex;
    background-color: $adminGrey;
    align-items: center;
    min-height: 49px;
    padding-left: 8px;
  }

  &__roles-title {
    font-weight: bold;
  }

  &__roles-list {
    width: 100%;
    max-width: 320px;
    flex-wrap: wrap;
    margin-left: 40px;
  }

  &__role {
    display: flex;
    width: 100%;
    max-width: 200px;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__role:last-child {
    display: flex;
    margin-bottom: 5px;
  }

  &__password-main-wrapper {
    width: 100%;
  }
}
