@import '../../../../styles/colors.scss';

.header-app {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding: 16px 0px;
    position: relative;
    height: 80px;
    width: 100%;
    max-width: 1240px;
    z-index: 1000;

    &__exit {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid $defaultColor;
        width: 100%;
        max-width: 70px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__left-side {
        display: flex;
        align-items: center;
    }

    &__phone-icon {
        margin-right: 5px;
    }

    &__logo {
        background-color: $defaultColor;
        border-radius: 0 0 10px 10px;
        padding: 30px;
        width: 100%;
        max-width: 210px;
        height: 115px;
        margin-bottom: -32px;
        z-index: 999;
        cursor: pointer;
    }

    &__contact-info {
        display: flex;
        align-items: center;
        margin-left: 40px;
    }

    &__phone-call {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21.6px;
        color: $phoneColor;
        font-weight: 700;
    }

}

@media (max-width: 1240px) {
    .header-app {
        padding-right: 15px;
        padding-left: 15px;
    }
}
