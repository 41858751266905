@import '../../../../styles/colors.scss';

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.personal-info {
  position: relative;

  &__user-email {
    font-family: 'Open Sans Bold';
    font-weight: normal;

    @include only-ie-11 {
      font-family: 'Open Sans', sans-serif;
    }
  }

  &__first-row {
    background: $whiteColor;
    color: $defaultColor;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    min-height: 86px;
    align-items: center;
    width: 100%;
    max-width: 1240px;
    min-height: 91px;
    height: 50px;
  }

  &__second-row-wrapper {
    width: 100%;
    background: $defaultColor;
  }

  &__second-row {
    color: $whiteColor;
    background: $defaultColor;
    margin: 0 auto;
    max-width: 1240px;
    display: flex;
    justify-content: space-between;
    min-height: 103px;
    align-items: center;
    width: 100%;
    min-height: 105px;
    height: 50px;
  }

  @media (max-width: 1240px) {
    &__second-row, &__first-row{
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &__right-side, &__right-side-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 460px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  &__right-side-bottom {
    min-height: 63px;
  }

  &__change-data, &__change-data-bottom {
    display: flex;
    min-height: 20px;
    cursor: pointer;
  }

  &__change-data-bottom {
    text-decoration-line: underline;
    font-weight: normal;
  };

  &__change-data-bottom:hover {
    text-decoration-line: underline;
  };

  &__personal-name {
    display: flex;
    font-family: 'Open Sans Bold';
    font-size: 18px;
    line-height: 25px;
    color: $personalNameColor;
    margin-left: 5.1%;
    text-align: left;
    word-break: break-all;

    @include only-ie-11 {
      font-family: 'Open Sans', sans-serif;
    }
  }

  &__personal-data-right, &__personal-data-right-full-info {
    display: flex;
    min-height: 20px;
  }

  &__personal-data-right {

    @include only-ie-11 {
      margin-bottom: 20px;
    }
  }

  @include only-ie-11 {
    &__personal-data-right:last-child,
    &__personal-data-right-full-info:last-child {
      margin-bottom: 0px;
    }
  }

  &__personal-data-right:last-child,
  &__personal-data-right-full-info:last-child {
    margin-bottom: 0px;
  }


  &__personal-data-right-full-info {
    cursor: pointer;
  }

  &__user-icon {
    margin-right: 10px
  }

  &__personal-contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__left-side {
    display: flex;
    align-items: center;
    width: 70%;
  }

  &__left-side-bottom {
    display: flex;
    align-items: center;
    width: 70%;
  }

  &__personal-data-bank {
    min-height: 63px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    margin-left: 5.1%;
  }

  &__bank-data {
    display: flex;
  }

  &__avatar {
    width: 100% !important;
    max-width: 169px !important;
    height: 169px !important;
  }

  &__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__avatar-control {
    margin-bottom: -88px;
    margin-top: 10px;
    min-width: 169px;
  }

  &__avatar-upload {
    background: $whiteColor;
    padding: 12px 11px;
    border-radius: 50%;
    display: flex;
    top: 0px;
    cursor: pointer;
  }

  &__test {
    width: 100% !important;
    max-width: 42px !important;
    height: 42px !important;
    margin-top: -44px;
    margin-left: 128px;
    position: absolute;
  }

  &__main-number {
    background: transparent;
    color: $whiteColor;
    border: none;
    font-family: 'Open Sans Bold';
    line-height: 19px;

    @include only-ie-11 {
      font-family: 'Open Sans', sans-serif;
    }
  }

}
