.final-stage-form {
  margin: 50px;
  width: 467px;

  &__info-message {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
  }

}
