@import '../../../../styles/colors.scss';

.admin-burger-menu {
  &__button {
    background-color: transparent;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    border: none;
    color: $adminSideMenuItemColor;
    font-weight: bold;
    height: 17px;
    cursor: pointer;

    &:hover {
      color: $whiteColor;
    }

    &:last-child {
      margin-top: 20px;
    }

    &:focus {
      outline: none;
    }
  }
}

.bm-burger-button {
  display: none;
}

.bm-burger-bars {
  background: $adminSideMenuBackground;
}


.bm-cross-button {
  height: 24px;
  width: 24px;

  &:focus {
    outline: none;
  }
}

.bm-cross {
  background: $adminSideMenuCrossColor;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 250px;
}

.bm-menu {
  background: $adminSideMenuBackground;
  padding: 2.5em 1.8em;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: $adminSideMenuBackground;
}

.bm-item-list {
  padding: 0.8em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: $adminSideMenuBackground;;
}
