@import './../../../../styles/colors.scss';

$maxHeightSlider: 822px;
$maxWidthSlider: 3000px;
$maxWidthContent: 795px;
$noResponsiveWidth: 1920px;
$maxHeightSlideWithCorr: $maxHeightSlider + 5px;
$maxHeightSlide: $maxHeightSlider - 2;

.main-slider {
  background-repeat: no-repeat;
  background-size: 100% $maxHeightSlideWithCorr;
  background-position-x: 50%;
  background-position-y: - 5px;
  height: 100%;
  
  @media only screen and (max-width: $noResponsiveWidth) {
    background-size: $noResponsiveWidth $maxHeightSlideWithCorr;
  }

  &__content {
    max-width: $maxWidthContent;
    width: 100%;
    background-color: $defaultColor;

    &_right {
      min-height: 500px;
      max-height: $maxHeightSlide;

      @media only screen and (max-width: $maxWidthContent) {
        margin-left: 0;
      }
    }

    &_middle {
      display: flex;
      justify-content: center;
    }
  }

  &__conrol-panel {
    width: 100%;
  }
}

.contract-info-slider {
  width: 100%;
  display: flex;
  justify-content: center;

  &__slider-content {
    max-width: $maxWidthSlider;
    width: 100%;
  }

  &__slider {
    min-height: 500px;
    max-height: $maxHeightSlider;
  }
}
