@import '../../styles/colors.scss';

.admin-page-wrapper {
  * {
    font-family: 'Segoe UI', 'Open Sans', sans-serif;
  }
  
  &__main {
    min-height: calc(100vh - 100px);
  }
}
