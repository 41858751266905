@import '../../../styles/colors.scss';

.veil {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.popup-container {
   position: fixed;
   top: 51%;
   left: 51%;
   transform: translate(-51%, -51%);
   border: 1px solid $popUpBorder;
   background: $whiteColor;
   border-radius: 5px;
   max-height: 80%;
   overflow: auto;
   cursor: default;

   &__close-btn {
       position: absolute;
       right: 0;
       cursor: pointer;
       outline: none;
       margin: 20px 20px 0px 0px;
       
       .close.icon {
           color: $closeButtonPopUp;
       }
   }
}
