@import '../../../../styles/colors.scss';

.sign-in-form {
  padding: 50px 84px;
  width: 100%;
  max-width: 637px;

  &__container {
    width: 470px;
  }

  &__captcha-input-wrapper {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  &__captcha-wrapper {
    display: flex;
    justify-content: center;
  }

  &__reset-password {
    color: $defaultColor;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: underline;

    &_reset {
      display: flex;
      justify-content: center;
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__tab-button {
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 20px ;
    cursor: pointer;
    color: $grayTextColor;
    transition: all ease-in-out .2s;
    border-bottom: 2px solid transparent;
    &-active {
      border-bottom: 2px solid $buttonGreen;
      color: $buttonBorder;
    }
  }

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 20px;
    color: $buttonBorder;
  }

  &__password-wrapper {
    letter-spacing: 5px;

    input::placeholder {
      letter-spacing: normal;
    }
  }

  &__password-field {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__sign-in-button {
    width: 100% !important;
    max-width: 157px !important;
  }

  &__sign-up-button {
    width: 100%;
    max-width: 292px;
    background: $whiteColor !important;
    color: $buttonBorder !important;
    border: 1px solid $buttonBorder !important;
  }

  &__control-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__additional-info {
    margin-top: 30px;
  }

  &__description {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }

  &__missing-password {
    color: $greyText;
    margin-right: 5px;
    display: none;
  }

  &__return-access {
    display: none;
    text-decoration: underline;
    cursor: pointer;
    color: $defaultColor;
  }

}
