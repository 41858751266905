@import "src/styles/colors";

p {
  margin: 0;
}

.veil {
  backdrop-filter: blur(5px);
  cursor: auto;
}

.acceptedPopup {
  width: 700px;
  padding: 35px 35px 0px 35px;
  overflow: hidden;

  &__text-agreement {
    margin: 15px 0px 0px 0px ;
    font-size: 23px;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    margin: 5px 0px;
  }

  &__arrow-icon {
    margin-left: 8px;
    transform: rotate(0deg) translateY(1px);

    &_opened {
      transform: rotate(180deg) translateY(0);
    }
  }

  &__button {
    text-align: center;
    border: 1px solid $defaultColor;
    border-radius: 5px;
    background-color: $whiteColor;
    width: 206px;
    height: 38px;
    color: $defaultColor;
    font-size: 17px;
  }

  &__accepted-rules {
    width: 650px;
    height: 250px;
    overflow-y: scroll;
    border: 1px solid $defaultColor;
    border-radius: 5px;
    margin-top: 15px;
  }

  &__accepted-rules-text {
    width: 650px;
    height: 250px;
    font-size: 10px;
    margin-top: 15px;
    cursor: auto;
    border: 1px solid $defaultColor;
  }

  &__submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto 15px auto;
    border: 1px solid $popUpBorder;
    border-radius: 5px;
    background-color: $buttonGreen;
    width: 206px;
    height: 38px;
    color: $whiteColor;
    font-size: 17px;

    &_disabled {
      background-color: $disabledButton;
    }
  }

  &__paragraph {
    text-indent: 20px;
  }

  &__head-paragraph {
    text-indent: 20px;
    font-weight: bold;
  }

  &__text-container {
    text-align: left;
    padding: 0 10px;
  }
  &__text-attention {
    text-indent: 20px;
    color: $redColor;
  }
}
