@import 'src/styles/colors.scss';

.assets-table {
  min-width: 650px;
  max-width: 650px;

  &__row {
    display: block;
    margin: 20px 0;
    background: $whiteColor;
    border-radius: 5px;
    border: 1px solid $lightBlockBorder;
  }

  &__loader {
    margin-bottom: 20px;
  }

  &__block {
    padding: 20px;
  }

  &__current-date {
    text-align: left;
    margin-bottom: 30px;
    font-size: 16px;
  }

  &__blocks-header {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: left;
  }

  &__arrow-icon {
    margin-left: 5px;
  }

  th {
    font-weight: 600;
  }

  tr {
    height: 48px;
  }

  tbody {
    tr {
      border-top: 1px solid $selectBorder;
    }
  }

   th,td {
     &.first {
       text-align: left;
     }
   }

  &__profit {
    color: $profit;
  }

  &__loss {
    color: $loss;
  }

  &__description {
    display: flex;
    margin-left: 20px;
  }

  &__border {
    border-left: 1px solid $defaultColor;
    height: 100%;
  }

  &__description-text {
    padding-left: 20px;
    margin: auto;
  }
}
