@import '../../../../styles/colors.scss';

.admin-auth-form {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__captcha-input-wrapper {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  &__captcha-wrapper {
    display: flex;
    justify-content: center;
  }

  &__submit-button {
    width: 155px;
  }

  &__error-message {
    margin: 0;
    padding: 0;
    height: 25px;
    color: $redColor;
  }

}
