.investment-income-popup {
  height: 515px;
  max-width: 560px;
  width: 100%;
  padding: 0 50px 50px;

  &__table {
    text-align: center;
    margin-top: 50px;
    max-height: 439px;
  }

  .popup-container {
    &__close-btn {
      margin: 5px 14px 0px 0px;
    }
  }

  .info-block-mini {
    max-width: 100%;
    text-align: left;
    margin-top: 20px;
  }

}
