@import '../../../../styles/colors.scss';


.change-password-form {
  margin: 50px 84px;
  width: 469px;

  &__header, &__header-second {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: $buttonBorder;
  }

  &__small-header {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 41px;
    color: $buttonBorder;
    margin-top: 20px;
  }

  &__header-second {
    margin-top: 20px;
  }

  &__input-field-password {

    &_error {
      border: 1px solid $errorColor;
    }

    margin-top: 20px;
  }

  &__info-block {
    margin-top: 30px;
  }

  &__control-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  &__password-wrapper {
    letter-spacing: 5px;

    input::placeholder {
        letter-spacing: normal;
    }
  }
}
