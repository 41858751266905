@import '../../../../styles/colors';

.email-validation-message {
  display: flex;
  justify-content: center;
  align-items: baseline;

  &__icon {
    color: $errorColor;
  }

  &__message {
    color: $errorColor;
    text-align: left;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
  }
}