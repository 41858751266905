@import 'src/styles/colors.scss';

.isz-additional-services-data {
  background-image: url('./../../../../../assets/images/isz-redemption-sums.png');

  &__wrapper {
    width: 100%;
    max-width: 1240px;
    display: flex;
    margin: 0 auto;
    flex-direction: row-reverse;
    height: 100%;
  }

  &__header-form-text {
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: $whiteColor;
    text-align: left;
    width: 100%;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }

  &__header-item {
    font-size: 17px;
    line-height: 20px;
    color: $whiteColor;

    &:nth-child(1) {
      margin-left: 50px;
    }
    &:nth-child(2) {
      margin-left: 250px;
    }
    &:nth-child(3) {
      margin-left: 100px;
    }
  }

  &__header-wrapper {
    width: 100%;
    margin-top: 100px;
    display: inline-flex;
    justify-content: flex-start;
  }

  &__content {
    max-width: 881px;
    width: 100%;
    padding: 35px 60px;
  }

  &__service-name {
    font-weight: normal;
  }

  .transparent-table {
    &__row {
      align-items: center;
      background-color: #2e426e !important;
      margin-bottom: 30px;
    }
  }

  &__table {
    max-width: 761px;
    width: 100%;

    tr {
      max-width: 761px;
      min-height: 60px;
      display: grid;
      grid-template-columns: 40% 30% 30%;
      justify-items: start;
      height: 100%;
      border-radius: 5px;
      padding: 5px 0px 20px 0px;
    }
    p {
      grid-area: desc;
      width: 720px;
      font-size: 12px;
      margin-top: 20px;
    }

    tbody {
      display: inline !important;
    }

    td, th {
      width: 259px;
      padding: 0px;
      margin-left: 21px;

      &:first-child {
        width: 247px;
      }

      &:last-child {
        width: 146px;
        text-align: left;
      }
    }
  }

}
