@import '../../../../styles/colors.scss';

.support-block {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  background: $whiteColor;
  padding: 40px 40px 40px 70px;
  display: flex;
  min-height: 498px;
  justify-content: space-between;

  &__wrapper {
    width: 100%;
    background: $backgroundSupport;
    padding: 66px 0;
  }

  &__left-side {
    display: flex;
    align-items: center;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 57px;
    color: $personalNameColor;
    width: 100%;
    max-width: 443px;
  }

  &__description {
    font-size: 18px;
    line-height: 27px;
    color: $personalNameColor;
    margin-top: 25px;
    width: 100%;
    max-width: 488px;
  }

  &__right-side {
    background: $defaultColor;
    padding: 30px;
    width: 100%;
    max-width: 518px;
    border-radius: 5px;
  }

  &__text {
    text-align: left;
  }

  &__email-desctription {
    font-size: 12px;
    line-height: 16px;
    color: $whiteColor;
    margin: 16px 0px 10px 0px;
    text-align: left;
  }

  &__message-field {
    width: 100%;
    max-width: 458px;
    height: 189px;
    border-radius: 5px;
    resize: none;
    padding-left: 16px;
    padding-top: 14px;
  }

  textarea::placeholder {
    font-size: 12px;
    line-height: 20px;
    color: $buttonBorder;
  }

  &__send-button {
    width: 100%;
    max-width: 170px;
    margin-right: 30px !important;
  }

  &__control-form {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }

  &__agreement-text {
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    color: $whiteColor !important;
    cursor: pointer;
    margin-left: 20px;
  }
}
