@import "src/styles/colors";

.applicationFormed {
  margin: 0px 35px 35px 35px;
  display: flex;

  &__container {
    position: relative;
  }

  &__back-button {
    border: none;
    background-color: $whiteColor;
    padding: 0px;
    position: absolute;
    top: 36px;
    right: 30px;
  }

  &__inner-container {
    padding: 30px;
  }

  &__formed-text {
    font-size: 27px;
    color: $defaultColor;
    text-align: center;
    margin-top: 64px !important;
  }

  &__divider {
    border: 1px solid rgba(22, 46, 94, 0.75);
    margin: 35px 0px;
  }

  &__questions-block {
    text-align: center;
    margin-top: 62px;
    color: $defaultColor;
    font-size: 17px;
  }

  &__phone-block {
    margin-bottom: 18px;
  }

  &__block-text {
    display: inline;
    margin-left: 7px;
  }
}
