@import './../../../../../styles/colors.scss';

.isz-insureds-data {
  background-image: url('./../../../../../assets/images/isz-data-main.png');

  &__content {
    padding: 30px 71px 0;
  }

  &__status {
    margin: 30px 0;
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__header-form-text {
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: $whiteColor;
    text-align: left;
    width: 100%;
    letter-spacing: 1.5px;
    margin: 35px 0px 20px 0px;
  }

  &__insurer {
    display: flex;
    line-height: 20px;
    color: $whiteColor;
    border-bottom: 1px solid $tablePassportBack;
    padding-bottom: 20px;
  }

  &__form-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: $whiteColor;
    text-align: left;

    &_second {
      margin-top: 20px;
    }
  }

  &__insurer-name {
    color: $whiteColor;
    font-size: 17px;
    text-align: left;
    word-break: break-all;
    padding-top: 6px;
    padding-left: 5px;
  }

  .transparent-table__value:first-child  {
    font-weight: normal;
    word-break: break-word;
    text-align: left;
  }

  &__wrapper {
    width: 100%;
    max-width: 1240px;
    display: flex;
    margin: 0 auto;
    flex-direction: row-reverse;
    height: 100%;
  }

  &__beneficiary-by-low {
    width: 100%;
    text-align: left;
    color: $whiteColor;
    margin-top: 10px;
  }

  &__beneficiary {
    margin-top: 10px;
    border-bottom: 1px solid $tablePassportBack;
  }

  &__table {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__table-element {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__table-element-fio {
    text-align: left;
    width: 75%;
    color: $whiteColor;
    font-size: 14px;
  }

  &__table-element-params {
    width: 50%;
    height: 35px;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    background-color: $backgroundInsuredBeneficiariesParams;
    color: $whiteColor;
    font-size: 14px;
  }

  .transparent-table {
    &__row {
      &_first:nth-child(2n+1) {
        background-color: unset;
      }
    }
  }

   &__table-insurers {
    width: 100%;
    display: block;

    tbody {
      width: 100%;
      display: block;
       tr {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 40px;
        justify-content: space-between;
        border-radius: 5px;
        margin-bottom: 0px;

        td {

          &:last-child, &:nth-child(2) {
            padding: 0px;
            display: block;
          }

          &:last-child {
            margin-right: 20px;
          }
          &:first-child {
            padding: 0px;
            display: block;
            width: 209px;
          }
        }
       }
    }
  }

  &__table-insurers {

    tbody {

      tr {

        td {
          width: 100% !important;
        }
      }
    }
  }
}
