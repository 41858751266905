@import '../../../../styles/colors.scss';

.flex-table-contr-agents {
  
  &__control-btns {
    display: flex;
  }

  &__control-btn {
    height: 28px;
    width: 25px;
  }

  &__wrapper, &__find-button {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: $whiteColor;

    &_choosed {
      background: $adminGrey;
    }
  }
}

.ui.search .prompt {
  border-radius: unset;
}

.ui.search>.results {
  max-height: 100px;
  overflow: auto;
}
