@import "src/styles/colors";

.sms-block {
  &__sms-block {
    width: 80%;
    display: flex;
    align-items: center;
  }

  &__agreement {
    color: $defaultColor;
    text-align: left;
  }

  &__sms-button-send {
    border: 1px solid $buttonGreen;
    border-radius: 5px;
    color: $buttonGreen;
    font-size: 17px;
    padding: 10px 25px;
    background-color: $whiteColor;
    white-space: nowrap;

    &_disabled {
      color: $grayTextColor;
      border: 1px solid $grayTextColor;
      pointer-events: none;
    }
  }

  &__sms-text-send {
    text-align: left;
    margin-left: 35px;
    color: $defaultColor;
    font-size: 17px;
  }

  &__sms-code {
    display: flex;
    text-align: center;
    border: 1px solid $cardBorderColor;
    border-radius: 5px;
    padding: 7px 35px;
    color: $defaultColor;
    font-size: 17px;
  }

  &__sms-code-block {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }

  &__sms-code-text {
    margin-left: 18px;
    font-size: 17px;
    color: rgba(22, 46, 94, 0.5);
    border-bottom: 1px solid rgba(22, 46, 94, 0.5);;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $popUpBorder;
    border-radius: 5px;
    background-color: $buttonGreen;
    width: 206px;
    height: 38px;
    color: $whiteColor;
    font-size: 17px;

    &_disabled {
      background-color: $whiteColor;
      border: 1px solid $buttonGreen;
      color: $grayTextColor;
      pointer-events: none;
    }
  }

  &__error {
    position: absolute;
    color: $redColor;
    text-align: left;
  }

  &__dis-btn {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }
}
