@import './../../../../../styles/colors.scss';

.isz-main-data {
  background-image: url('./../../../../../assets/images/isz-data-main.png');

  &__button-container {
    width: 100%;
    text-align: left;
    margin-top: 30px;
  }

  &__content {
    padding: 30px 71px 0;
  }

  &__status {
    margin: 30px 0;
  }

  &__table {
    td {
      white-space: normal;
    }
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__wrapper {
    width: 100%;
    max-width: 1240px;
    display: flex;
    margin: 0 auto;
    flex-direction: row-reverse;
    height: 100%;
  }

  &__saving {
    margin: 30px 50% 10px 0px;
    font-weight: bold;
    font-size: 17px;
    color: $whiteColor;
  }

  &__popup {
    margin-right: 5px;
  }

  &__additional-table {
    display: flex;
    flex-direction: column;
    width: 95%;
    color: $whiteColor;
    font-size: 17px;
    padding: 20px;
    background-color: $backgroundSecondRowTransparentTable;
    border-radius: 5px;
  }

  &__payments {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__additional {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__cumulative-amount {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }

  &__additional-income {
    display: flex;
  }

  &__additional-income-name {
    margin-right: 5px;
  }
}
