@import "src/styles/colors";

.paymentEnd {
  display: flex;
  margin-left: 20%;

  &__container {
    position: relative;
  }

  &__loader {
    position: relative;
    margin: 50px auto 50px auto;

    &_disabled {
      display: none;
    }
  }

  &__back-button {
    border: none;
    background-color: $whiteColor;
    padding: 0px;
    position: absolute;
    top: 36px;
    left: 30px;
  }

  &__arrow-forward {
    border: none;
    background-color: $whiteColor;
    padding: 0px;
    position: absolute;
    top: 36px;
    right: 30px;
  }

  &__arrow {
    width: 32px;
    height: 32px;

    &_right {
      transform: rotate(180deg);
    }
  }

  &__inner-container {
    padding: 30px;
  }

  &__header {
    font-size: 27px;
    color: $defaultColor;
    text-align: left;
    margin-top: 64px !important;
  }

  &__divider {
    border: 1px solid rgba(22, 46, 94, 0.75);
    width: 80%;
    margin: 35px 0px;
  }

  &__text {
    color: $defaultColor;
    margin: 0px 15px 18px 0px;
  }

  &__field {
    width: 206px;
    height: 38px;
    border: 1px solid $checkboxBorder;
    border-radius: 5px;
    background-color: $checkboxBackground;
    color: $checkboxBorder;
    padding: 7px 5px;
    text-align: center;
    margin: 0px;
  }

  &__checkbox {
    margin-bottom: 15px;
  }

  &__button-container {
    margin-top: 35px;
    text-align: left;
  }

  &__button {
    text-align: center;
    border: 1px solid $defaultColor;
    border-radius: 5px;
    background-color: $secondaryButtonColor;
    color: $whiteColor;
    width: 206px;
    height: 38px;
    font-size: 17px;

    &_disabled {
      background-color: $whiteColor;
      color: $grayTextColor;
      pointer-events: none;
    }
  }

  &__disabled {
    color: $grayTextColor;
  }

  &__back-sum {
    text-align: left;
    margin-top: 20px;
  }

  &__comment-block {
    text-align: left;
    margin-top: 35px;
  }

  &__comment-label {
    color: $defaultColor;
  }

  &__comment {
    width: 80%;
    min-height: 110px;
    max-height: 500px;
    padding: 8px 14px;
    border: 1px solid $cardBorderColor;
    border-radius: 5px;
    color: $secondaryButtonColor;
    font-size: 17px;
  }

  textarea::placeholder {
    color: $inputDashed;
    font-size: 14px;
  }
}
