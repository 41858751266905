@import '../../../../styles/colors.scss';

.custom-select-mine {
  display: flex;
  border-radius: 5px;
  height: 99%;
  width: 100%;
  max-height: 45px;
  max-width: 200px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;

  &__current-value {
    position: relative;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 999;
  }

  &__input {
    width: 100%;
    border: 1px solid $selectBorder !important;
    margin-top: 10px;
    border-radius: 5px;

    &_open {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

  }

  &__list {
    position: fixed;
    z-index: 1000;
    width: 100%;
    overflow: auto;
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
  }

  &__list-item {
    cursor: pointer;
    border-bottom: 1px solid $selectBorder;
    background: $whiteColor;
    width: 100%;
    border-right: 1px solid $selectBorder;
    border-left: 1px solid $selectBorder;
  }

  &__list-item:hover {
    background: $adminGreyFormColor;
  }

  &__list-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

}
