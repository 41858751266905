.contracts-cards-block {
	margin: 0 auto;
	padding-top: 25px;
	padding-bottom: 40px;
	width: 100%;
	max-width: 1240px;
	text-align: left;

	&__no-contracts {
		font-size: 25px;
	}

	&__title {
		font-size: 35px;
		margin: 0 0 50px 20px;
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		gap: 20px;
	}

	.ui.loader {
		position: relative;
	}

	&__no-contracts {
		text-align: center;
		font-size: 25px;
	}
}
