@import "src/styles/colors";

.saveFile {
  &__avatar-upload {
    width: 70%;
    display: flex;
    align-items: center;
    padding: 14px 36px;
    border: 1px dashed $defaultColor;
    border-radius: 5px;
    background-color: $checkboxBackground;
    cursor: pointer;
  }

  &__input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__upload-text {
    margin-left: 18px;
    color: $inputDashed;
  }

  &__image {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 18px;
    color: $defaultColor;
  }

  &__del-icon {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__image-desc {
    font-size: 17px;
    text-align: left;
  }

  &__error {
    color: $adminInputErrorBorder;
    text-align: left;
    margin-bottom: 10px;
  }
}
