@import '../../../../styles/colors.scss';

.switcher-admin-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $adminTransparentBorder;

  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $adminFormColor;
    width: 100%;
    height: 26px;
    cursor: pointer;
    
    &:focus {
      outline: none;
    }

    &_active {
      background-color: $adminActiveFormColor;
    }
  }

  &__tab-text {
    margin: 0;
    padding: 0;
    margin-right: 10px;
    font-size: 18px;
  }

  &__arrow {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: $adminButtonColor;
    border-right: 0;

    &_down {
      margin-top: 8px;
      border: 10px solid transparent;
      border-top-color: $adminButtonColor;
      border-bottom: 0;
    }
  }
}
