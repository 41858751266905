@import '../../../../../styles/colors.scss';

.user-card-contacts {

  &__first-row {
    display: flex;
    background: $adminGrey;
    margin-bottom: 3px;
  }

  @media (max-width: 1200px) {

    &__first-row {
      flex-wrap: wrap;
    }

  }

  &__second-row {
    background: $borderColor;
  }

  &__personal-block {
    display: flex;
    font-size: 16px;
    line-height: 19px;
    margin: 4px 0px;
  }

  &__title, &__value {
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  &__value {
    background: $adminActiveFormColor;
  }

  .custom-table-row__item {
    background: $whiteColor !important;
  }

  .custom-table-row__headers {

    * {
      background: $tableHeaderColor !important;
    }

  }

  &__table {
    height: 187px;
    overflow: auto;
  }

}
