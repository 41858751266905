@import '../../../../styles/colors.scss';

.flex-table-contracts {

  &__control-btns {
    display: flex;
  }
  
  &__control-btn {
    height: 28px;
    width: 25px;
  }
}
