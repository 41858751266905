@import '../../../../styles/colors.scss';

.pagination-interface {
  width: 100%;
  height: 35px;
  background-color: $whiteColor;
  display: flex;
  justify-content: flex-end;
  border: 2px solid $borderColor;

  .caret.icon {
    color: $adminButtonColor;
    font-size: 3rem;
    width: 12px;
    padding-top: 0px;
    height: unset;
  }

  &__icon-caret {
    outline: none;
    cursor: pointer;
  }

  &__pagination-control {
    display: flex;
    align-items: center;

    input {
      width: 30px;
      height: 30px;
      border: none;
      outline: none;
      text-align: center;
    }
  }

  &__pages {
    display: flex;
    height: 100%;
    margin: 0 5px;
    font-size: 20px;
  }

}

