.user-card-contracts {

  &__table {
    max-height: 170px;
    overflow: auto;
  }

  &__control-btn {
    cursor: pointer;
    width: 100%;
    padding: 0;
  }

}
