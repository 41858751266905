@import '../../../../../styles/colors.scss';

.user-information {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &__text-wrapper,
  &__password-change-date {
    margin: 0px;
  }

  &__first-row {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 1px;
    background-color: $adminGrey;
  }

  &__control-password {
    background-color: $whiteColor;
    cursor: pointer;
    height: 80%;
  }

  &__password-row {
    width: 100%;
    margin: 0 10px;
  }

  &__control-btn {
    width: 36px;
    height: 29px;
  }

  &__ban-button {
    min-height: 35px;
    max-width: 100%;
    width: 165px;
    font-size: 17px;
    line-height: 20px;
    border-radius: 5px;
  }

  &__pernament-cache {
    display: flex;
    align-items: center;
    width: 30%;
  }

  &__password-title {
    display: flex;
    align-items: center;
    width: 30%;
  }

  &__password-input {
    width: 100%;
    height: 25px;
  }

  &__label-text {
    width: 25%;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
    margin: 0;
  }

  &__prop-text {
    word-wrap: break-word;
    width: 75%;
    text-align: left;
    margin: 0;
  }

  &__password-change-date {
    width: 100%;
    max-width: 100px;
    text-align: left;
  }

  &__password-input {
    width: 100%;
  }

  &__prop-content {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $adminGrey;
    margin-bottom: 1px;
    min-height: 40px;
  }

  &__third-row {
    padding: 6px 8px;
    background-color: $adminGrey;
  }

  &__caching-title {
    display: flex;
    align-items: center;
  }

  &__cache-data {
    display: flex;
    align-items: center;
  }

  &__text-wrapper {
    text-align: left;
    width: 40%;

    &_lastChild {
      width: 30%;
    }

    &_controlBlock {
      width: 50%;
    }

    &_controlBlockFirst {
      width: 50%;
    }
  }

  &__password-wrapper {
    display: flex;
    width: 70%;
    align-items: center;
  }

  &__four-row {
    padding: 3px 7px 10px;
    background-color: $adminGrey;
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
  }

  &__lock-block-row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__lock-block {
    width: 77%;
  }

}
