@import 'src/styles/colors.scss';

.date-line-select {
  &__wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  &__line {
    background: $lineColor;
    height: 1px;
    width: calc(100% - 78px);
    margin: auto;
  }

  &__line-disabled {
    margin-left: auto;
    height: 100%;
    background: $lightBlockBorder 50%;
  }

  &__current-period {
    margin-top: -2px;
    margin-left: auto;
    height: 4px;
    background: green;
  }

  &__item-hide {
    display: none;
  }

  &__delta-smaller {
    position: absolute;

  }

  &__actual-point-position {
    position: absolute !important;
  }

  &__actual-point-display {
    display: none !important;
  }

  &__item-price {
    color: $personalNameColor;
    background: transparent;
    border: none;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__item {
    color: $personalNameColor;
    background: transparent;
    border: none;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    width: 80px;
    text-align: center;
    position: relative;

    &:disabled {
      pointer-events: none;
      color: $lightBlockBorder;

      &:before {
        background: $lightBlockBorder 50%;
      }
    }

    &-not-passed {
      &:before {
        height: 6px;
        width: 1px;
        display: block;
        background: $lineColor;
        content: '';
        margin-bottom: 6px;
        color: $defaultColor;
      }
    }

    &-selected {
      color: $defaultColor;
      font-weight: 700;

      &-indicator {
        background: $defaultColor;
        border-radius: 7px 7px 7px 0;
        transform: rotate(-45deg);
        width: 15.27px;
        height: 15.27px;
        position: absolute;
        top: -25.27px;
      }
    }
    &-selected__q {
      position: absolute;
    }

    &-passed {
      &:before {
        display: block;
        content: url('./../../../../assets/svg/graphCircle.svg') !important;
        margin-top: -7px;
        margin-bottom: 2px;
      }
    }
  }
}

@media (max-width: 991px) {
  .date-line-select {
    overflow-x: scroll;
  }
}
