@import '../../../../styles/colors.scss';

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.header-image {
  position: relative;
  height: 473px;
  background: url('../../../../assets/images/mainBackground.png');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__back-image {
    width: 100%;
    height: 473px;
  }

  &__hello-name {
    max-width: 490px;
    width: 100%;
    color: $defaultColor;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 57px;
    padding: 20px;
    background: $whiteColor;
    word-break: break-word;

    @include only-ie-11 {
      max-width: 600px;
    }
  }

  &__hello-wrapper {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 53px auto;
  }

  @media (max-width: 1240px) {
    &__hello-wrapper {
        margin: 0 15px 53px 15px;
    }
  }

}
