.captcha-block {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
  flex-direction: column;

  &__icon {
    top: 5px;
    right: 5px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__repeat-text {
    margin: 0;
    margin-right: 6px;
    padding: 0;

    font-weight: bold;
    font-size: 0.9rem;
    text-decoration: underline;
  }

  &__button-repeat {
    display: flex;
  }

  &__button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .ui.input {
    height: 38px;
  }

  .ui.icon {
    font-size: 0.8rem;
  }
}
