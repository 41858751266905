@import '../../../../styles/colors.scss';

.registration-form {

  background: url('../../../../assets/images/blurBack.png') no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 80px);
  width: 100%;

  &__blur-back {
      width: 100%;
  }

}

.stage-one-form {
  margin: 50px 84px;
  width: 467px;

  &__radio {
    margin-bottom: 20px;
    width: 100%;
  }

  &__radio-button {
    height: 30px;
    background-color: $whiteColor;
    border: 1px solid $buttonBorder;
    border-radius: 5px;
    color: $buttonBorder;
    font-weight: bold;
    line-height: 19px;
    margin-right: 10px;

    &_is-selected {
      background-color: $buttonGreen;
      border-radius: 5px;
      border: 1px solid $buttonGreen;
      color: $whiteColor;
    }
  }

  .react-datepicker-popper {
    top: -145px !important;
    left: 168px !important;
  }

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: $buttonBorder;
    margin-bottom: 20px;
  }

  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__tab-button {
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    color: $grayTextColor;
    transition: all ease-in-out .2s;
    border-bottom: 2px solid transparent;
    &-active {
      border-bottom: 2px solid $buttonGreen;
      color: $buttonBorder;
    }
  }

  &__info-button {
    height: 23px;
    width: 23px;
  }

  &__info {
    width: 25px;
    height: 25px;
  }

  &__info:hover &__tooltip {
    display:block;
  }

  &__tooltip {
    right: -47px;
    bottom: 50px;
    background-color: $whiteColor;
    display: none;
    position: absolute;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: 0 0 10px $popupOpacity;
    padding: 5px;
  }


  &__error-input {
    border: 1px solid $errorColor;
  }

  &__input-field {
    margin-top: 20px;
  }

  &__icon {
    position: fixed;
    left: 88%;
    top: 257px;
  }

  &__icon-description {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: $buttonBorder;
    margin-bottom: 20px;
  }

  &__control-btn {
    margin: 30px 0px;
    display: flex;
    justify-content: center;
  }

  .react-datepicker-wrapper {
    width: 100%;

    input {
      width: 100%;
      max-width: 469px;
      height: 48px;
      border-radius: 5px;
      background: $inputBackground;
      outline: none;
      border: none;
      padding: 14px 0px 14px 19px;
    }

  }

}

.stage-four-form {
  margin: 50px;
  width: 535px;

  &__error-text {
    width: 100%;
  }

  &__error {
    color: $whiteColor;
    width: 537px;
    background: $errorColor;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    padding: 10px;
    margin-bottom: 30px;
    position: relative;
  }

  &__error-icon {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  &__control-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

}

.stage-second-form {
  margin: 50px 84px;
  width: 467px;

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: $buttonBorder;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: $buttonBorder;
    margin: 20px 0px;
  }

  &__first-row, &__second-row  {
    display: flex;
    justify-content: space-between
  }

  &__second-row {
    margin-top: 20px;
  }

  &__control-btn {
    margin: 30px 0px;
    display: flex;
    justify-content: center;
  }

  &__first-row-input {
    width: 100%;
    max-width: 150px;
  }

  &__second-row-input {
    width: 100%;
    max-width: 230px;
  }

}

.stage-third-form {
  margin: 50px 84px;
  width: 467px;

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: $buttonBorder;
  }

  &__input-field-password {
    margin-top: 20px;
  }

  &__password-wrapper {
    letter-spacing: 5px;

    input::placeholder {
        letter-spacing: normal;
    }
  }

  &__info-block {
    margin-top: 30px;
  }

  &__control-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  &__error-input {
    border: 1px solid $errorColor;
    letter-spacing: 5px;

    input::placeholder {
        letter-spacing: normal;
    }
  }

}

.final-stage-form {
  margin: 50px;
  width: 467px;

  &__control-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  &__info-message {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 23px;
  }

}
