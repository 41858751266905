@import '../../../../styles/colors.scss';

.form-input-mini-label {
    width: 100%;
    max-width: 469px;
    height: 34px;
    border-radius: 5px;
    background: $inputBackground;
    outline: none;
    border: none;
    padding: 14px 0px 14px 19px;
    margin-bottom: 10px;

    &__wrapper {
        position: relative;
        border-radius: 5px;
        width: 100%;
        max-width: 469px;
    }

    &__label {
        top: 13px;
        right: 16px;
    }

    &__icon {
        position: absolute;
        top: 13px;
        right: 16px;
    }

}

::-webkit-input-placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $buttonBorder;
}
::-moz-placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $buttonBorder;
}
:-moz-placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $buttonBorder;
}
:-ms-input-placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $buttonBorder;
}
