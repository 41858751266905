@import '../../../../styles/colors.scss';

.contract-header-form {
  align-items: center;
  display: flex;

  &__icon {
    background-color: $whiteColor;
    height: 131px;
    width: 131px;
    border-radius: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
  }

  &__img {
    width: 64px;
    height: 64px;
  }

  &__header-form-text {
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: $whiteColor;
    text-align: left;
    max-width: 300px;
    width: 100%;
    letter-spacing: 1.5px;
  }

}
