@import 'src/styles/colors.scss';

.warning {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 35px 0px 0px 5px;

  &__border {
    border-right: $warningBorderColor;
    padding: 0px 10px;
    height: 60px;
    width: 1px;
  }

  &__text {
    font-size: 17px;
    color: $defaultColor;
    width: 70%;
    padding-left: 10px;
  }
}
