@import '../../../../styles/colors.scss';

.error-import-popup {
  padding: 15px 0 0;
  border-radius: 0;
  max-width: 670px;
  width: 100%;

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  &__text {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    text-align: center;
    font-size: 15px;
    margin-right: 3px;
    padding: 0 15px;
  }

  &__error-window {
    height: 400px;
    margin: 0 15px;
    padding: 5px;
    background-color: $whiteColor;
    border: 1px solid $adminBorderColor;
    border-bottom: 0;
    overflow: auto;
  }

  &__veil {
    background-color: $adminPopupTransparent;
  }

  &__error {
    display: flex;
    align-items: center;
  }

  &__error-text {
    margin-left: 5px;
  }
}
