$defaultColor: #162E5E;
$defaultOpacity: rgba(22, 46, 94, 0.05);
$popupOpacity: rgba(0,0,0,0.4);
$veil: #00000061;
$borderColor: #8C8C8C;
$Woodsmoke: #040405;
$buttonGreen: #06722C;
$whiteColor: #FFFFFF;
$blackColor: #000000;
$redColor: #ff0000;
$closeButtonPopUp: #162E5E;
$inputBackground: #F3F3F3;
$buttonBorder: #30445A;
$greyText: #B2BAC2;
$errorColor: #EB5757;
$personalNameColor: #333333;
$tablePassportBack: #F2F2F2;
$popUpBorder: #F3F4F7;
$footerColorWhite: #FCFCFC;
$phoneColor: #003167;
$backgroundSupport: #F8F9FB;
$backgroundSecondRowTransparentTable: #2e426e;
$borderScrollControl: #162E5E;
$backgroundInsuredBeneficiariesParams: #213866;
$borderAutocall: #162E5EBF;
$secondaryButtonColor: #006DF0;
$backgroundScrollControl: #8A3A8A;
$notActiveTab: #BDBDBD;
$whiteTableBorder: #F3F4F7;
$whiteTableFirstColumn: #F2F2F2;
$lightBlockBorder: #D0D1D3;
$lineColor: #b9b9b9;
$grayTextColor: #7d7d7d;
$profit: #219B35;
$loss: #D22929;
$activeTabOpacity: rgba(255, 255, 255, 0.5);
$checkboxBorder: #162E5E80;
$checkboxBackground: #EFF1F5;
$inputDashed: #A7AAAD;
$disabledButton: #98D09A;
$warningBorderColor: 1px solid rgba(22, 46, 94, 0.75);

// administration
$adminInputErrorBackground: #fff6f6;
$adminInputErrorBorder: #ff5b5b;
$adminButtonColor: #FF7A00;
$adminFormColor: #F5F5F5;
$adminActiveFormColor: #C4C4C4;
$adminTransparentBorder: #E4E3E3;
$adminGreyFormColor: #a9a9a9;
$adminSideMenuItemColor: #c5c5c5;
$adminBorderColor: #a9a9a9;
$adminCheckboxBackground: #008001;
$adminPopupTransparent: #00000095;
$adminSideMenuBackground: #373a47;
$adminSideMenuCrossColor: #bdc3c7;
$adminGrey: #E5E5E5;
$tableRowColor: #F0F0F0;
$tableHeaderColor: #DADADA;
$documentFormColor: #f7f7f7;
$selectBorder: #dedede;
$scroll: #4e5d7b;

// contract-card
$cardBorderColor: #eff1f5;
$cardColorHover: #edeff3;
$statusBlockColor: #e8eaef;
$cardBorderColorHover: #9ea8bc;
$statusBlockColorHover: #d8dce4;
$cardBorderActiveColor: #4f6185;
