.admin-card-form {
  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__table {
    max-height: 560px;
    overflow: auto;
    width: 60%;
    margin-right: 4px;

    &_max {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    height: 577px;
  }

  &__footer-btn {
    width: 165px;
    max-width: 100%;
  }
}
