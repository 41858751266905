@import '../../../../../styles/colors.scss';

.isz-risks-data {
  background-image: url('./../../../../../assets/images/isz-data-risks.png');

  &__table-first-column {
    margin-left: auto;
  }

  &__modal {
    height: 100px;
    top: auto;
    left: auto;
  }

  &__header-surv {
    display: flex;
    align-items: center;
  }

  &__header-form-text {
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: $whiteColor;
    text-align: left;
    width: 100%;
    letter-spacing: 1.5px;
    margin: 35px 0px 20px 0px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    max-width: 881px;
    width: 100%;

    .transparent-table {
      tbody {
        border-radius: 5px;
        background-color: $backgroundSecondRowTransparentTable;
        font-weight: 400;
        max-height: 360px;
        height: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .contract-header-form {

    .contract-header-form__header-form-text {
      max-width: 250px;
    }
  }

  &__table {
    max-width: 761px;
    width: 100%;

    tr {
      min-height: 60px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: auto !important;
      margin: 10px 0px;
    }

    td, th {
      width: 259px;
      padding: 0px;
      display: block;
      margin-left: 42px;

      &:first-child {
        width: 247px;
        margin-left: 20px;
      }

      &:last-child {
        width: 146px;
        text-align: left;
        margin-left: 27px;
      }
    }

    th {
      text-align: center !important;
    }

    td:nth-child(2) {
      text-align: center;
    }

    td:last-child {
      text-align: center;
    }
  }

  &__table-second {
    max-width: 761px;
    width: 100%;

    tr {
      min-height: 60px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: auto !important;
      margin: 10px 0px;
    }

    td, th {
      width: 259px;
      padding: 0px;
      display: block;
      margin-left: 42px;

      &:first-child {
        width: 247px;
        margin-left: 20px;
      }

      &:last-child {
        width: 146px;
        text-align: left;
        margin-left: 27px;
      }
    }

    th {
      text-align: center !important;
    }

    td:nth-child(2) {
      text-align: center;
    }

    td:last-child {
      text-align: center;
    }
  }

  &__logo {
    margin-bottom: 65px;
  }

  &__wrapper {
    width: 100%;
    max-width: 1240px;
    display: flex;
    margin: 0 auto;
    flex-direction: row-reverse;
    height: 100%;
  }
}
