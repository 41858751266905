@import './../../../../styles/colors.scss';

.reverse-white-table {
  border-spacing: 0px;
  max-width: 500px;
  width: 100%;
  border-collapse: unset;

  &__row {
    height: 70px;

    &:first-child {
      .reverse-white-table {
        &__td {
          &:first-child {
            border-radius: 5px 0 0;
          }
          
          &:last-child {
            background-color: $whiteTableFirstColumn;
            border-radius: 0 5px 0 0;
          }
        }
      }
    }

    &:last-child {
      .reverse-white-table {
        &__td {
          &:first-child {
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $whiteColor;
          }

          &:last-child {
            border-radius: 0 0 5px 0;
          }
        }
      }
    }
  }

  &__td {
    &:first-child {
      color: $whiteColor;
      border: 1px solid $whiteColor;
      border-bottom: 0;
      border-right: 0;
      font-size: 12px;
      font-weight: bold;
    }

    &:last-child {
      background-color: $whiteColor;
      border: 1px solid $whiteTableBorder;
      border-bottom: 0;
      border-right: 0;
      font-size: 13px;
    }
  }
}
