@import 'src/styles/colors.scss';

.ui.accordion .active.title .chevron.down.icon {
  transform: rotate(180deg);
}

.ui.accordion .title {
  cursor: unset;
  margin-bottom: 15px;
}

.ui.accordion .title:not(.ui) {
  padding: 0px;
}

i.chevron.down.large.icon {
  cursor: pointer;
}

.tabs {
  max-width: 1250px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
}

.tab {
  width: 100%;
  color: $defaultColor;
  overflow: hidden;
  background-color: $backgroundSupport;
  margin-bottom: 5px;

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    font-weight: bold;
    cursor: pointer;
    background-color: $whiteColor;
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .35s;
    }
  }

  &-content {
    max-height: 0;
    padding: 0 1em;
    background: white;
    transition: all .35s;
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
}

.profitability {

  &__strategy_loader {
    margin-bottom: 30px;
  }

  &__wrapper-tab {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 12%;
    background-color: $defaultColor;
    border-radius: 5px;
    margin-bottom: 30px;
  }

  &__barrier {
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
  }

  &__term-end {
    color: $whiteColor;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
  }

  &__border {
    border-bottom: 0.5px solid $lightBlockBorder;
    width: 90%;
    margin: auto;
  }

  &__barrier-percent {
    font-size: 18px !important;
    font-weight: 300;
    color: $whiteColor;
  }

  &__barrier-field {
    font-size: 12px;
    font-weight: 300;
    color: $whiteColor;
    margin-bottom: 5px;
  }

  &__scale {
    margin-top: 100px;
  }

  &__observation-date {
    max-width: 350px;
    padding: 1px 7px;
    text-align: left;
    background: $defaultOpacity;
    border-radius: 5px;
  }

  &__observation-date-text {
    text-align: center;
    font-size: 16px;
    color: $personalNameColor;
  }

  &__quart-tabs {
    display: flex;
    justify-content: space-around;
    margin: 0px 39px 30px 39px;
  }

  .tab-select {

    &__tab-selected {
      background-color: $whiteColor;
      color: inherit;
    }

    &__border {
      border: none
    }

    &__wrapper {
      margin: 0px 0px 30px 0px;
      justify-content: space-around;
      padding: 0px 39px;
    }

    &__period {
      display: none;
    }
  }

  &__accordion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
  }

  &__accordion-closed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    border: 1px solid $lightBlockBorder;
    border-radius: 5px;
    background: $whiteColor;
  }

  &__accordion-closed_disabled {
    opacity: 0.6;
  }

  &__accordion-strategy {
    margin-left: 20px;
    font-weight: bold;
  }

  &__accordion-strategy-open {
    align-self: start;
    padding-top: 7px;
  }

  &__accordion-strategy-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  &__accordion-strategy-info-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 250px;
    text-align: right;
    margin-right: 10px;
    padding: 7px 0px;
  }

  &__info-content-head {
    font-weight: 700;
  }

  &__popup {
    display: flex;
    width: 450px;
    min-height: 100px;
  }

  &__popup-question-icon {
    display: flex;
    align-items: center;
    font-size: 60px;
    color: $defaultColor;
    margin-right: 12px;
  }

  &__popup-icon {
    align-self: center;
    margin-right: 23px;
  }

  &__popup-border {
    border-left: 1px solid $defaultColor;
    margin-right: 23px;
  }

  &__icon-chevron {
    cursor: pointer;
  }

  &__popup-text {
    color: $grayTextColor;
    margin: auto 0;
  }

  &__barrier-block {
    padding: 20px;
    display: flex;
    margin: 20px 0;
    background: $whiteColor;
    border-radius: 5px;
    border: 1px solid $lightBlockBorder;
  }

  &__barrier-block-content {
    display: flex;
  }

  &__barrier-block-left {
    display: flex;
    align-items: center;
    min-width: 650px;
  }

  &__barrier-block-left-autocall {
    background: $defaultOpacity;
    border-radius: 5px;
    padding: 40px 50px;
  }

  &__barrier-block-left-header {
    color: $defaultColor;
    font-size: 18px;
  }

  &__barrier-bloc-left-percents {
    color: $defaultColor;
    font-size: 30px;
    font-weight: 600;
  }

  &__barrier-block-right {
    padding: 40px 50px;
    margin-left: 20px;
  }

  &__barrier-bloc-right-percents {
    color: $defaultColor;
    font-size: 30px;
    font-weight: 400;
  }

  &__barrier-description {
    display: flex;
    margin-left: 20px;
  }

  &__barrier-border {
    border-left: 1px solid $defaultColor;
    height: 100%;
  }

  &__barrier-description-text {
    padding-left: 20px;
    margin: auto;
    text-align: left;
    color: $grayTextColor;
  }

  &__from-start-price-asset {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  &__one-asset-text {
    font-weight: bold;
    position: relative;
    bottom: -3px;
  }

  &__big-arrow {
    height: 50px;
    width: 40px;
  }

  &__date-wrapper {
    width: 450px;
    margin-left: -10px;
  }

  &__date-rows {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__one-date {
    color: $defaultColor;
    font-size: 20px;
    font-weight: bold;
    margin-top: -25px;
  }

  &__wrapper {
    background: $backgroundSupport;
    padding: 50px 0;
    position: relative;
  }

  &__back-button {
    width: 234px;
    height: 50px;
    border-radius: 5px;
    background-color: $whiteColor;
    border: 1px solid $defaultColor;
    color: $defaultColor;
    position: absolute;
    top: -90px;
    left: 0;
    font-size: 16px;

    &_error {
      top: -50px;
      margin-top: 30px;
    }

    @media (max-width: 1360px) {
      top: -40px;
      left: 20px;
    }
  }

  &__page-title {
    margin: 0 0 50px;
    font-size: 35px;
    font-weight: 600;
    color: $defaultColor;
  }

  &__main-block {
    display: flex;
    width: 100%;
    max-width: 1240px;
    margin: auto;
    position: relative;
  }

  &__block {
    padding: 20px;

    &-header {
      font-size: 14px;
      color: $personalNameColor;
      font-weight: 700;
      margin-bottom: 32px;
      text-align: left;
    }

    &_error {
      margin-top: 60px;
      width: 100%;
      height: 241px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__contract-block {
    width: 278px;
    margin-right: 20px;
    height: max-content;
    border: 1px solid $lightBlockBorder;
    border-radius: 5px;
    padding: 30px;
    background-color: $whiteColor;
  }

  &__profitability-section {
    width: 100%;
  }

  &__blocks-header {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: left;
  }

  &__current-date {
    text-align: left;
    margin-bottom: 30px;
    font-size: 16px;
  }

  &__row {
    display: block;
    margin: 20px 0;
    background: $whiteColor;
    border-radius: 5px;
    border: 1px solid $lightBlockBorder;
  }

  &__loader {
    margin-bottom: 20px;
  }

  &__barrier {
    font-size: 60px;
    font-weight: 700;
    color: $defaultColor;
    width: 175px;

    &-asset {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin: 20px 0;

      .profitability__barrier {
        width: max-content;
        line-height: 1;
      }
    }

    &_one-asset {
      font-size: 26px;
      width: unset;
      margin-right: 5px;
    }
  }

  &__common-text {
    color: $grayTextColor;
    max-width: 180px;
    font-size: 14px;
    margin: auto;

    &_memory {
      max-width: 100%;
      text-align: left;
      margin-top: 10px;
    }
  }

  &__common-text-no-barrier {
    margin-bottom: 20px;
  }

  &__coupon-info {
    width: 650px;
    font-size: 18px;
    text-align: left;

    .profitability__common-text {
      max-width: 100%;
      margin-top: 10px;
    }
  }

  &__coupon {
    display: flex;
    margin-top: 30px;
  }

  &__coupon-description {
    width: 200px;
    color: $personalNameColor;
    font-size: 16px;
    margin-bottom: 10px;
  }


  &__coupon-total {
    padding: 16px;
    border-radius: 5px;
    background-color: $defaultOpacity;
  }

  &__coupon-value {
    color: $defaultColor;
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
  }

  &__profit {
    color: $profit;
    display: flex;
  }

  &__loss {
    color: $loss;
    display: flex;
  }
}

.transparent {
  background: transparent !important;
}


@media (max-width: 1366px) {
  .profitability {
    &__main-block {
      padding: 20px;
      flex-direction: column;
    }

    &__contract-block {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 768px) {
  .profitability {
    &__row {
      flex-direction: column;
    }

    &__barrier-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
