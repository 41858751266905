@import '../../../../styles/colors.scss';

.flex-table-administrators {

  &__control-btns {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: $whiteColor;

    &_choosed {
      background: $adminGrey;
    }

    &_error {
      background-color: #f9c6c6 !important;
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: $whiteColor;

    &_choosed {
      background: $adminGrey;
    }

    &_error {
      background-color: #f9c6c6 !important;
    }

  }

  &__input {
    border: none;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px #4D90FE;
      box-shadow: 0 0 5px #4D90FE;
    }
  }

  &__input {
    background-color: $whiteColor;
    color: $blackColor;
  }

  &__input-wrapper {
    margin-left: 5px;
    width: 100%;
  }
}
