@import 'src/styles/colors.scss';

.profitability-contract {

  &__autocall {
    font-size: 14px;
    color: $defaultColor;
    margin-bottom: 12px;
  }

  &__autocall-border {
    border-bottom: 1px solid $defaultColor;
    margin-top: 14px;
  }

  &__border {
    border-bottom: 1px solid $borderAutocall;
    margin: 15px 0px;
  }

  &__couple {
    color: $personalNameColor;
    margin-bottom: 30px;
    text-align: left;
  }

  &__value {
    font-size: 16px;
  }

  &__label {
    font-weight: 700;
  }
}

@media (max-width: 1366px) and (min-width: 600px) {
  .profitability-contract {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &__autocall-description {
      margin: 20px;
    }

    &__couple {
      margin: 20px;
    }
  }
}

@media (max-width: 599px) {
  .profitability-contract {
    &__wrapper {
      text-align: center;
    }
  }
}
