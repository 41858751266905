@import 'src/styles/colors.scss';

.insuranceContract {
  border: 1px solid $cardBorderColor;
  border-radius: 5px;
  padding: 10px 20px;
  width: 70%;

  &__header {
    text-align: left;
    color: $defaultColor;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__flex-item {
    display: flex;
    flex-direction: column;
    font-size: 17px;
  }

  &__item-status {
    border: 1px solid $secondaryButtonColor;
    border-radius: 5px;
    color: $whiteColor;
    background-color: $secondaryButtonColor;
  }

  &__item-status-text {
    padding: 5px 10px;
    font-size: 12px;
  }
}
