@import "src/styles/colors";

%for-button {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $statusBlockColor;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  &__actions-veil {
    backdrop-filter: blur(0px);
    cursor: auto;
  }

  &__btn {
    @extend %for-button;
    font-weight: bold;
  }

  &__arrow-icon {
    margin-left: 8px;
    transform: rotate(180deg) translateY(1px);

    &_opened {
      transform: rotate(0deg) translateY(0);
    }
  }

  &__actions-container {
    display: block;
    border: none;
    position: absolute;
    bottom: -78px;
    left: 371px;
    z-index: 100;
  }

  &__actions {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__action-btn {
    @extend %for-button;
    margin-bottom: 4px;
    text-align: left;
    white-space: nowrap;

    &:hover {
      background-color: $secondaryButtonColor;
      color: $whiteColor;
    }
  }
}
