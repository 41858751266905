@import '../../../../../styles/colors.scss';

$activeCardBorderWidth: 4px;
$cardBorderWidth: 1px;
$cardBorderWidthDifference: $activeCardBorderWidth - $cardBorderWidth;

.contract-card {
	width: 386px;
	background-color: $whiteColor;
	border: $cardBorderWidth solid $cardBorderColor;
	border-radius: 10px;
	box-shadow: 0px 4px 50px $statusBlockColor;
	padding: 10px 16px;
	text-align: left;
	cursor: pointer;
	&_active {
		border: $activeCardBorderWidth solid $cardBorderActiveColor;
		padding: 10px - $cardBorderWidthDifference 16px - $cardBorderWidthDifference;
	}

	&__upper-block {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: 10px;
	}

	&__year {
		font-weight: bold;
		font-size: 50px;
		color: $defaultColor;
		opacity: 0.2;
		line-height: 1;
		white-space: nowrap;
	}

	&__name-status-block {
		text-align: right;
		div:first-child {
			margin-bottom: 5px;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 185px;
			white-space: nowrap;
		}
	}

	&__status-block {
		padding: 4px 10px;
		border-radius: 5px;
		background-color: $statusBlockColor;
	}

	&__img {
		margin-right: 4px;
		width: 19px;
		height: 20px;
	}

	&__bold-text {
		font-weight: bold;
	}

	&__lower-block {
		div:not(:last-child) {
			margin-bottom: 5px;
		}
	}

	&__big-month {
		font-size: 30px;
	}

	&__month {
		font-size: 20px;
	}

	&__actions-wrapper {
		text-align: right;
	}
}
